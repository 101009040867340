html, body, iframe, #root, .Routes, .Tv {
  width: 100%!important;
  height: 100%!important;
  background-color: #000;
}

.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  background-color: #f2ce3c;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: stretch infinite 5s ease-out; */
    animation-name: stretch;
    animation-duration: 3s; 
    animation-timing-function: ease-out; 
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1600px;
  width: 95%;
  position: relative;
}

.title-container .info-drawer {
  position: absolute;
  right: 0;
}

.App-header h2 {
  margin: 0;
  color: #f2ce3c;
  margin-top: 8px;
}

.App-link {
  display: flex;
  align-items: center;
  margin: 16px;
}

.YouTubeFrame {
  height: 95%;
  width: 95%;
  margin: 16px 0;
  max-width: 1600px;
}

.aspect-ratio--16x9 {
  /* width: 100%;
  height: 0;
  padding-bottom: 56.25%; */
}

.YouTubeFrame div {
  position: relative;
  height: 75vh;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
   
.YouTubeFrame iframe,
.YouTubeFrame object,
.YouTubeFrame embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.skip-button {
  margin-bottom: 16px !important;
}

.info-buttons {
  display: flex;
  justify-content: space-between;
  width: 95%;
  max-width: 1600px;
}

.info-drawer button {
  color: #f2ce3c;
  border-color: #f2ce3c;
}

.info-drawer button:hover {
  background-color: rgba(248, 148, 6, .1);
}

.info-open .info-paper {
  max-width: 1600px;
  background-color: #9e9e9e;
  display: flex;
}

.info-paper h3,
.info-paper h4,
.info-paper p {
  margin-left: 16px;
  margin-right: 16px;
}


/* DisclaimerBar.js */

.disclaimer-bar {
  text-align: left;
}

.disclaimer-bar h4 {
  margin: 0;
}

.disclaimer-bar .MuiAlert-action {
  align-items: end;
}

.twitch-drawer a {
  text-decoration: none;
  color: inherit;
}

.twitch-drawer .twitch-subheader {
  font-weight: bold;
  font-size: 20px;
  color: #282c34;
}


/* Media queries for responsive breakpoints */

@media (max-width: 1300px) {
  .YouTubeFrame div {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; 
    height: 0; 
    overflow: hidden;
  }
}

@media (max-width: 600px) {
  .title-container .info-drawer {
    position: relative;
    padding-top: 10px;
    margin-bottom: 0;
  }
  .info-buttons, .title-container {
    flex-direction: column;
  }
  .info-drawer {
    margin-bottom: 16px;
  }
}

@keyframes stretch {
  0% {
    transform: scale(.75);
  }
  100% {
    transform: scale(1);
  }
}
